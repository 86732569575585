<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">
          <FormulateForm name="personal-info-form" class="personal-info-form" v-model="values" @submit="personalInfoHandler">
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">DADOS PESSOAIS</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="first-name" v-model="firstName" validation="required" placeholder="Insira o seu primeiro nome" label="Primeiro Nome"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="last-name" v-model="lastName" validation="required" placeholder="Insira o seu último nome" label="Último Nome"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="select" name="gender" v-model="gender" validation="required" label="Género" :options="{f: 'Feminino', m: 'Masculino'}"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="date" name="birthdate" v-model="birthdate" validation="required" placeholder="" label="Data de Nascimento"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="select" name="country" v-model="country" validation="required" label="País" :options="this.$root.countries"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="city" v-model="city" validation="required" placeholder="Insira a sua cidade" label="Cidade"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="select" name="language" v-model="language" validation="required" label="Língua" :options="{pt: 'Português', en: 'Ingles', es: 'Espanhol'}"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="email" v-model="email" validation="required" placeholder="Insira o seu e-mail" label="E-mail"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="phone" v-model="phone" validation="required" placeholder="Insira o seu número de telefone" label="Telefone"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-group-wrapper">
              <p class="section-title reserved-area-form-title">Perfil</p>
              <div class="reserved-area-form-group">
                <div class="row">
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="weight" v-model="weight" validation="required" placeholder="Insira o seu peso" label="Peso"/>
                    </div>
                  </div>
                  <div class="reserved-area-form-column col-12 col-lg-6 col-xl-12 col-xxl-6">
                    <div class="form-field">
                      <FormulateInput type="text" name="height" v-model="height" validation="required" placeholder="Insira a sua altura" label="Altura"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
              <p>{{ formResponseMessage }}</p>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Guardar</button>
            </div>
          </FormulateForm>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "reserved-area-index",
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      firstName: this.$root.userInfo.name,
      lastName: this.$root.userInfo.last_name,
      gender: this.$root.userInfo.gender,
      country: this.$root.userInfo.country_id,
      email: this.$root.userInfo.email,
      city: this.$root.userInfo.city,
      language: this.$root.userInfo.lang,
      birthdate: this.$root.userInfo.birth_date,
      phone: this.$root.userInfo.phone,
      weight: this.$root.userInfo.weight,
      height: this.$root.userInfo.height,
      showMessage: false,
      formResponseMessage: ''
    };
  },
  metaInfo () {
    return {
      title: this.$root.translations.definicoes,
    }
  },
  components: {
    reservedAreaMenu,
  },
  methods: {
    personalInfoHandler() {
      this.height = this.height.replace(",", "");

      const requestBody = {
        updateType: 'profile',
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender,
        country: this.country,
        email: this.email,
        city: this.city,
        language: this.language,
        birthdate: this.birthdate,
        phone: this.phone,
        weight: this.weight,
        height: this.height,
      };

      let authKey = window.$cookies.get("authKey");

      axios
          .post(process.env.VUE_APP_API_URL + 'customer', qs.stringify(requestBody), {
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            console.log(response);
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
    setData() {
      this.firstName = this.$root.userInfo.name
      this.lastName = this.$root.userInfo.last_name
      this.gender = this.$root.userInfo.gender
      this.country = this.$root.userInfo.country_id
      this.email = this.$root.userInfo.email
      this.city = this.$root.userInfo.city
      this.language = this.$root.userInfo.lang
      this.birthdate = this.$root.userInfo.birth_date
      this.phone = this.$root.userInfo.phone
      this.weight = this.$root.userInfo.weight
      this.height = this.$root.userInfo.height
    }
  },
  async created() {
    await this.$root.getCustomer()

    this.setData()

    this.$root.pageTitle = this.$root.translations.definicoes;
  }

}
</script>